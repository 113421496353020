import React from "react"
import { navigate } from "gatsby"
import Cookies from "universal-cookie"
import { Helmet } from "react-helmet"

import Layout from "../../components/SnickerHungerInsurenceComponents/components/layout"

import Agegate from "../../components/SnickerHungerInsurenceComponents/components/agegate"

const AgeGate = () => {
  let ageCookie = new Cookies()

  if (ageCookie.get("isOldEnough")) {
    navigate("/snickers-hunger-insurance/")
  }

  return (
    <Layout>
      <Helmet>
        <link
          rel="stylesheet"
          href="../../SnickersHungerInsurance/css/app.css"
        />
        <link
          rel="stylesheet"
          href="../../SnickersHungerInsurance/css/responsive.css"
        />
      </Helmet>
      <Agegate />
    </Layout>
  )
}

export default React.memo(AgeGate)
